.footer { 
  bottom: 0; 
  height: 30px; /* Set the fixed height of the footer here */
  line-height: 30px; /* Vertically center the text there */
  background-color: #e6e6e6;
  position: relative; 
  clear:both; 
  font-size: 0.7rem; 
  display: flex;
  flex-wrap: wrap; 
}